<template lang="pug">
  .create-organization-form
    AppOverlayLoader(:state="loading")
    .create-organization-form-container
      .create-organization-form-block
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.name')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.name')"
              required
            )
          .create-organization-form-field
            BFormInput(
              type="text"
              v-model="organization.name"
              name="organization-name"
              :placeholder="$t('organization_management.create_organization.placeholders.name')"
              :state="$v.organization.name.$error ? false : null"
              @input="handleInputName($event)"
              :disabled="this.isEdit"
            )
            BFormInvalidFeedback(v-if="formatNameError") {{ formatNameError }}
            BFormInvalidFeedback(v-if="minLengthNameError") {{ minLengthNameError }}

        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.schema_name')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.schema_name')"
              required
            )
          .create-organization-form-field
            BFormInput(
              type="text"
              v-model="organization.schema_name"
              name="schema-name"
              :placeholder="$t('organization_management.create_organization.placeholders.schema_name')"
              :state="$v.organization.schema_name.$error ? false : null"
              :disabled="this.isEdit"
              :maxlength="8"
              @keypress="handleKeyPressSchemaName($event)"
            )
            BFormInvalidFeedback(v-if="$v.organization.schema_name.$error") {{ $t("validations.at_least_1_letter") }}
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.max_active_shops_count')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.max_active_shops_count')"
              required
            )
          .create-organization-form-field
            BFormInput(
              type="number"
              step="1"
              min="1"
              max="500"
              v-model="organization.max_active_shops_count"
              name="max-active-shops"
              :placeholder="$t('organization_management.create_organization.placeholders.max_active_shops_count')"
              :state="$v.organization.max_active_shops_count.$error ? false : null"
              @input="changeMaxShopsValue"
            )
            BFormInvalidFeedback(v-if="$v.organization.max_active_shops_count.$error") {{ $t("validations.invalid_max_active_shops_count") }}
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.max_competitors_count')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.max_competitors_count')"
              required
            )
          .create-organization-form-field
            BFormInput(
              type="number"
              step="1"
              min="1"
              max="500"
              v-model="organization.max_competitors_count"
              name="max-competitors"
              :placeholder="$t('organization_management.create_organization.placeholders.max_competitors_count')"
              @input="changeMaxCompetitorsValue"
            )
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.price_management_mode')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.price_management_mode')"
              required
            )
          .create-organization-form-field
            AppDropdown.price-management-mode(
              :followReferenceBoundary="false"
              close-on-select
              order-direction="keep"
              :items="modes"
              :value="currentMode"
              @select="selectMode"
            )
        .create-organization-form-row(
          v-if="organizationId"
          :data-cy="$t('organization_management.create_organization.fields.price_management_car_classes')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.price_management_car_classes')"
              required
            )
          .create-organization-form-field
            AppDropdown.price-car-classes(
              checkbox
              batch-select
              multiple
              allow-empty
              searchable
              close-on-select
              :lazy="false"
              :followReferenceBoundary="false"
              :loading="carClassesLoading"
              :placeholder="$t('shops_car_class_matching.select_car_class')"
              :value="selectedCarClasses"
              :items="carClasses"
              @select="selectCarClasses"
            )
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.shared_inventory_enabled')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.shared_inventory_enabled')"
            )
          .create-organization-form-field
            AppCheckbox(
              v-model="organization.shared_inventory_enabled"
              name="shared-inventory-enabled"
            )
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.company_system_enabled')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.company_system_enabled')"
            )
          .create-organization-form-field
            AppCheckbox(
              disabled
              :value="organization.company_system_enabled"
              name="company-system-enabled"
            )
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.active')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.active')"
            )
          .create-organization-form-field
            AppCheckbox(
              v-model="organization.active"
              name="active"
            )
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.dp')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.dp')"
            )
          .create-organization-form-field
            AppCheckbox(
              :disabled="organizationAccount.dp"
              v-model="organization.dp"
              name="dp"
            )
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.inbound')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.inbound')"
            )
          .create-organization-form-field
            AppCheckbox(
              :disabled="organizationAccount.inbound"
              v-model="organization.inbound"
              name="inbound"
            )
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.nicolas')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.nicolas')"
            )
          .create-organization-form-field
            AppCheckbox(
              :disabled="organizationAccount.nicolas"
              v-model="organization.nicolas"
              name="nicolas"
            )
        .create-organization-form-row(
          v-if="organizationId && hasMetrodirect"
          :data-cy="$t('organization_management.create_organization.fields.md_reservations')"
        )
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.md_reservations')"
            )
          .create-organization-form-field
            AppButton.create-organization-form-send-reservations(
              title="organization_management.create_organization.buttons.send_data"
              name="send-reservations"
              @click="exportReservations(organizationId)"
            )
        .create-organization-form-row(v-if="organizationId && !organization.shared_inventory_enabled")
          .create-organization-form-row-label
            FormFieldLabel(
              :title="$t('organization_management.create_organization.fields.inventory_csv')"
            )
          .create-organization-form-field
            input(
              type="file"
              ref="fileInput"
              accept=".csv"
              @change="onCsvPicked"
            )
      .create-organization-form-block
        .create-organization-form-row(
          :data-cy="$t('organization_management.create_organization.fields.ota_ids')"
        )
          .create-organization-form-field
            .create-organization-form-row
              FormFieldLabel(
                :title="$t('organization_management.create_organization.fields.ota_ids')"
                required
              )
            .create-organization-form-select-batch
              AppButton.create-organization-form-select-batch-item(
                title="components.checkboxes_group.select_all"
                name="select-all"
                @click="selectAllOtas"
              )
              AppButton.create-organization-form-select-batch-item(
                title="components.checkboxes_group.clear_all"
                name="clear-all"
                @click="clearAllOtas"
              )
            .create-organization-form-accounts-count
              span.filter-title {{ $t("organization_management.create_organization.fields.max_ota_accounts_count") }}
            .create-organization-form-ota-list
              .create-organization-form-ota-row(
                  v-for="{ id, name } in translatedOtaList"
                )
                AppCheckbox.ota-names(
                  :key="id"
                  :label="name"
                  :value="isOtaSelected(id)"
                  @change="handleOtaSelect($event, id)"
                )
                .create-organization-form-field-accounts-number
                  AppNumberInput(
                    :min="1"
                    :max="99"
                    :value="get(organizationOtas, `${id}.maxOtaAccountsCount`)"
                    :disabled="!isOtaSelected(id)"
                    :invalid="isInvalidOtaAccountsCount[id]"
                    @input="setMaxOtaAccountsCount($event, id)"
                  )
            BFormInvalidFeedback.d-block(
              v-if="$v.organization.organization_otas_params.$error"
            ) {{ $t("validations.at_least_1_item") }}
    .errors
      BAlert(
        v-if="!!errors"
        :show="isErrorsAlertShown"
        variant="danger"
        dismissible
      )
        p(v-for="error in errors") {{ error }}
    .col
      .d-flex.justify-content-end
        AppSaveButton(
          title="actions.confirm"
          :disabled="loading"
          @save="saveOrganizationAccount"
        )
</template>

<script>
  // store module
  import organizationsModule from "@/config/store/maestro/organizations"
  import carClassesModule from "@/config/store/maestro/car_classes"

  // mixins
  import withValidations from "@/mixins/withValidations"
  import withStoreModule from "@/mixins/withStoreModule"

  // misc
  import { mapGetters } from "vuex"
  import { keyBy, values, transform, isEmpty, find, filter, get } from "lodash-es"
  import { ORGANIZATION_NAME_REGEXP, DISABLED, SERIALIZER_VIEW_EXTENDED, METRODIRECT_OTA } from "@/config/constants"
  import { getModes } from "@/config/translations_helper"

  const organizationsMixin = withStoreModule(organizationsModule, {
    name: "organizations",
    readers: { organizationsLoading: "loading" },
    actions: {
      fetchOrganizations: "FETCH_ITEMS",
      createOrganization: "CREATE_ITEM",
      updateOrganization: "UPDATE_ITEM",
      exportReservations: "FETCH_POST_ITEMS"
    }
  })

  const carClassesMixin = withStoreModule(carClassesModule, {
    name: "carClassMatching",
    readers: { carClassesLoading: "loading", carClasses: "items" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const validationsMixin = withValidations(({ required, minLength, maxLength, minValue, integer }) => ({
    organization: {
      name: {
        required,
        minLength: minLength(1),
        formatCheck: name => ORGANIZATION_NAME_REGEXP.test(name)
      },
      schema_name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(8)
      },
      organization_otas_params: {
        lengthCheck: value => value.length > 0
      },
      max_active_shops_count: {
        minValue: minValue(0),
        integer
      },
      max_competitors_count: {
        minValue: minValue(0),
        integer
      }
    }
  }))

  const defaultOrganizationAttributes = {
    name: "",
    schema_name: "",
    organization_otas_params: [],
    max_active_shops_count: 1,
    max_competitors_count: 0,
    price_management_mode: DISABLED,
    price_management_car_class_ids: [],
    inventory_csv: ""
  }

  export default {
    components: {
      AppButton: () => import("@/components/elements/AppButton"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    props: {
      organizationAccount: {
        type: Object,
        default: () => {
          return { ...defaultOrganizationAttributes }
        }
      }
    },

    mixins: [validationsMixin, organizationsMixin, carClassesMixin],

    mounted() {
      if (this.organizationId) {
        this.fetchCarClasses({
          pagination: { _disabled: true },
          filters: {
            matched: true,
            organization_id: this.organizationId
          }
        })
      }
    },

    created() {
      const selectedOtas = keyBy(this.organization.otas, "id")
      this.organizationOtas = this.translatedOtaList.reduce((object, { id }) => {
        object[id] = {
          id,
          selected: !!selectedOtas[id],
          maxOtaAccountsCount: selectedOtas[id]?.max_ota_accounts_count || 1
        }
        return object
      }, {})
      this.setOrganizationOtasParams()
    },

    data() {
      return {
        organization: { ...this.organizationAccount },
        organizationOtas: {},
        errors: [],
        organizationLoading: false,
        isErrorsAlertShown: false
      }
    },

    computed: {
      ...mapGetters(["translatedOtaList"]),

      modes() {
        return getModes(this.$i18n)
      },

      loading({ organizationLoading, organizationsLoading }) {
        return organizationsLoading || organizationLoading
      },

      isEdit() {
        return !!this.organization.id
      },

      formatNameError() {
        const { required, formatCheck } = this.$v.organization.name
        if (required && formatCheck) return

        return this.$t("validations.organization.invalid_name_format")
      },

      minLengthNameError() {
        const { required, minLength } = this.$v.organization.name
        if (required && minLength) return

        return this.$t("validations.at_least_1_letter")
      },

      isInvalidOtaAccountsCount() {
        return transform(
          values(this.organizationOtas),
          (obj, { id, maxOtaAccountsCount }) => {
            if (maxOtaAccountsCount < 1) obj[id] = true
            return obj
          },
          {}
        )
      },

      currentMode() {
        return find(this.modes, { id: this.organization.price_management_mode })
      },

      selectedCarClasses({ carClasses, organization: { price_management_car_class_ids: ids } }) {
        return filter(carClasses, carClass => (ids ? ids.includes(carClass.id) : false))
      },

      organizationId({ organizationAccount }) {
        return organizationAccount?.id
      },

      hasMetrodirect({ translatedOtaList }) {
        const metrodirectOta = translatedOtaList.find(({ rawName }) => rawName === METRODIRECT_OTA)
        const metrodirectId = get(metrodirectOta, "id", null)

        return !!metrodirectId && this.isOtaSelected(metrodirectId)
      }
    },

    methods: {
      get,

      onCsvPicked(event) {
        const files = event.target.files
        const fileReader = new FileReader()
        var vm = this
        fileReader.addEventListener("load", () => {
          vm.organization.inventory_csv = fileReader.result
        })
        fileReader.readAsText(files[0])
      },

      selectAllOtas() {
        this.setAllOtasSelected(true)
      },

      clearAllOtas() {
        this.setAllOtasSelected(false)
      },

      setAllOtasSelected(value) {
        this.translatedOtaList.forEach(({ id }) => (this.organizationOtas[id].selected = value))
        this.setOrganizationOtasParams()
      },

      setMaxOtaAccountsCount(value, otaId) {
        this.updateOrganizationOtaPartial(otaId, { maxOtaAccountsCount: Number(value) })
      },

      handleOtaSelect(value, otaId) {
        this.updateOrganizationOtaPartial(otaId, { selected: value })
      },

      updateOrganizationOtaPartial(otaId, changes) {
        this.organizationOtas[otaId] = { ...this.organizationOtas[otaId], ...changes }
        this.setOrganizationOtasParams()
      },

      isOtaSelected(id) {
        return this.organizationOtas[id]?.selected
      },

      setOrganizationOtasParams() {
        const organization_otas_params = values(this.organizationOtas).reduce(
          (otas, { id, selected, maxOtaAccountsCount }) => {
            if (selected) {
              otas.push({ ota_id: id, max_ota_accounts_count: maxOtaAccountsCount })
            }
            return otas
          },
          []
        )
        this.organization = { ...this.organization, organization_otas_params }
      },

      saveOrganizationAccount() {
        this.isErrorsAlertShown = false
        this.organizationLoading = true
        this.validateAttributes()
        if (!this.isValidOrganization || !isEmpty(this.isInvalidOtaAccountsCount)) {
          this.organizationLoading = false
          return
        }
        this.sendRequest()
          .then(() => {
            this.cancelValidation()
            this.fetchOrganizations({ serializer_view: SERIALIZER_VIEW_EXTENDED })
            this.$emit("close")
          })
          .catch(({ response: { data: { record } } }) => this.showErrorAlert(Object.values(record).flat()))
          .finally(() => (this.organizationLoading = false))
      },

      showErrorAlert(errors) {
        this.errors = errors
        this.isErrorsAlertShown = true
      },

      async sendRequest() {
        if (this.isEdit) {
          await this.updateOrganization(this.organization)
        } else {
          await this.createOrganization(this.organization)
        }
      },

      handleInputName(value) {
        if (!this.isEdit) {
          const schemaName = value
            .replace(/[^A-Za-z]+/g, "")
            .slice(0, 8)
            .toLowerCase()

          this.organization = { ...this.organization, schema_name: schemaName }
        }
      },

      handleKeyPressSchemaName(e) {
        if (/[a-z]/i.test(String.fromCharCode(event.charCode))) {
          return true
        }

        e.preventDefault()
      },

      changeMaxShopsValue(value) {
        const numberValue = value === "" ? null : Number(value)
        this.organization = { ...this.organization, max_active_shops_count: numberValue }
      },

      changeMaxCompetitorsValue(value) {
        const numberValue = value === "" ? null : Number(value)
        this.organization = { ...this.organization, max_competitors_count: numberValue }
      },

      selectMode({ id: price_management_mode }) {
        this.organization = { ...this.organization, price_management_mode }
      },

      selectCarClasses(car_classes) {
        const price_management_car_class_ids = car_classes.map(car_class => car_class.id)
        this.organization = { ...this.organization, price_management_car_class_ids }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .create-organization
    margin-top: 50px
    color: $default-black

    .col-xxl-6
      @media (min-width: 1599px)
        flex: 0 0 50%
        max-width: 50%

    &.loading
      opacity: 0.7
      cursor: not-allowed

    &-heading
      color: $default-purple
      font-weight: 300
      font-size: 1.4rem
      padding-bottom: 30px
      padding-left: 25px

    &-form
      position: relative

      &-container
        display: flex
        gap: 20px

      &-accounts-count
        text-align: right
        margin-bottom: 5px


        +filter-title

      &-select
        &-batch
          width: 100%
          display: flex
          align-items: center
          justify-content: space-between
          margin-bottom: 10px

          &-item
            height: 30px
            width: 40%
            padding: 0
            white-space: nowrap
            background: $default-white
            color: $default-purple
            border: 1px solid $default-purple
            font-size: 0.8rem

      &-send-reservations
        ::v-deep
          background-color: $default-purple
          color: $default-white
      &-row
        align-items: center
        display: flex
        margin: 0 0 20px 0
        width: 100%

        &-label
          width: 170px

        span
          font-size: 0.8rem

      &-ota-list
        overflow: auto
        height: fit-content
        width: 240px
        padding-right: 18px

      &-ota-row
        display: flex
        justify-content: space-between
        margin-bottom: 10px

      &-field
        padding: 0 6px
        width: 234px
        &-organizations
          width: 234px

        &-accounts-number
          width: 30px

        input
          transition: all 0.1s linear
          border-color: $border-element-color
          font-size: 0.8rem

          &:focus
            background: $default-gray-light

          &.is-invalid
            border-color: $default-red

  .errors
    .alert
      width: 100%

  .app-checkbox
    &.ota-names
      padding: 0px 0px
      font-size: 0.8rem
</style>
